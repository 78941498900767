let SERVER_ADDRESS = "http://localhost:8001";

const getServerAddress = () => {
  if (window.location.href.includes("https://cryptodynamic.eu")) {
    SERVER_ADDRESS = "https://cryptodynamic.eu";
  } else if (window.location.href.includes("https://www.cryptodynamic.eu")) {
    SERVER_ADDRESS = "https://www.cryptodynamic.eu";
  }
};
getServerAddress();
export default SERVER_ADDRESS;
