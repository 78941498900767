import SERVER_ADDRESS from "../../aaHoc/global";
import React, { useEffect, useState } from "react";
import axios from "axios";
import FundTransactions_TableLine from "./FundTransactions_TableLine";
import ActionModal from "../ActionModal";
import FundTransaction_Form from "./FundTransaction_Form";

const FundTransactions_Table = () => {
  const [transactionData, setTransactionData] = useState([]);

  useEffect(() => {
    axios.get(SERVER_ADDRESS + "/api/fund-transactions/get").then((res) => {
      setTransactionData(res.data);
    });
  }, []);

  const tableHeader = ["Date", "User", "Nosh", "Value", "Avg NAV"];
  return (
    <div className="fund-transactions-table">
      <div className="table-container">
        <ul className="table-header">
          {tableHeader.map((el) => (
            <li key={el}>
              <label htmlFor={el}>{el}</label>
            </li>
          ))}
        </ul>
        {transactionData &&
          transactionData.map((transaction, index) => (
            <FundTransactions_TableLine
              transaction={transaction}
              key={transaction.id}
              index={index}
            />
          ))}
      </div>
    </div>
  );
};

export default FundTransactions_Table;
