import SERVER_ADDRESS from "../../aaHoc/global";
import React, { useEffect, useState } from "react";
import ReactSwitch from "react-switch";

import axios from "axios";
import {
  Area,
  AreaChart,
  CartesianGrid,
  Tooltip,
  XAxis,
  YAxis,
  linearGradient,
  ResponsiveContainer,
  ReferenceLine,
} from "recharts";

import exportedStyles from "../../styles/_settings.scss";

const Chart_Nav = ({ showToggleAum }) => {
  const [duration, setDuration] = useState(181);
  const [fearAndGreed, setFearAndGreed] = useState();
  const [showAum, setShowAum] = useState(false);

  const handleToggleAum = () => {
    setShowAum(!showAum);
  };

  const headerData = [
    [30, "1 mois"],
    [91, "3 mois"],
    [181, "6 mois"],
    [365, "1 an"],
    [730, "2 ans"],
    [1800, "5 ans"],
    [3000, "Max"],
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const now = new Date();
        const thresholdDate = new Date(now - duration * 24 * 60 * 60 * 1000);

        const response = await axios.get(
          `${SERVER_ADDRESS}/api/aum/get-history`
        );
        const filteredData = response.data.filter(
          (item) => new Date(item.report_date) > thresholdDate
        );

        let formattedData = "";
        if (showAum === false) {
          formattedData = filteredData.map((item) => ({
            date: new Date(item.report_date).toLocaleDateString(),
            price: item.nav,
          }));
        } else if (showAum === true) {
          formattedData = filteredData.map((item) => ({
            date: new Date(item.report_date).toLocaleDateString(),
            price: item.aum,
          }));
        }

        setFearAndGreed(formattedData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [duration, showAum]);

  return (
    <div className="chart-container">
      <div className="app" style={{ textAlign: "center" }}>
        {showToggleAum ? (
          <div className="toggle-aum-container">
            <ReactSwitch
              checked={showAum}
              onChange={handleToggleAum}
              onColor={exportedStyles.chartColor1}
              offColor={exportedStyles.chartColor2}
            />
          </div>
        ) : (
          ""
        )}
      </div>
      <div className="nav-chart">
        <div className="btn-container">
          {headerData.map((el) => {
            return (
              <div
                key={el[0]}
                htmlFor={"btn" + el[0]}
                onClick={() => setDuration(el[0])}
                className={el[0] === duration ? "active-btn" : ""}
              >
                {el[1]}
              </div>
            );
          })}
        </div>
        <ResponsiveContainer width="100%">
          <AreaChart
            data={fearAndGreed}
            margin={{ top: 10, right: 10, left: 10, bottom: 0 }}
          >
            <defs>
              <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                <stop
                  offset="7%"
                  stopColor={exportedStyles.chartColor1}
                  stopOpacity={0.8}
                />
                <stop
                  offset="93%"
                  stopColor={exportedStyles.chartColor2}
                  stopOpacity={0}
                />
              </linearGradient>
            </defs>
            <XAxis dataKey="date" />
            <YAxis domain={["auto", "auto"]} />
            <Tooltip />
            <Area
              type="monotone"
              dataKey="price"
              stroke={exportedStyles.chartColor1}
              fillOpacity={1}
              fill="url(#colorUv)"
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default Chart_Nav;
