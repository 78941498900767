import SERVER_ADDRESS from "../aaHoc/global";
import axios from "axios";
import { createContext, useContext, useMemo, useState } from "react";

const AuthContext = createContext(null);
export const AuthProvider = ({ children }) => {
  const [jwtToken, setJwtToken] = useState(() => {
    const storedJwtToken = localStorage.getItem("jwt_token");
    return storedJwtToken;
  });
  const [connectionError, setConnectionError] = useState(null);

  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      const originalRequest = error.config;
      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        logout();
      }
      return Promise.reject(error);
    }
  );

  const token = localStorage.getItem("jwt_token");
  if (token) {
    axios.defaults.headers.common["x-access-token"] = token;
  }

  const login = async ({ email, password }) => {
    let connected = false;
    await axios
      .post(SERVER_ADDRESS + "/api/user-authentification", {
        email,
        password,
      })
      .then((res) => {
        if (res.data.status === "OK") {
          setJwtToken(res.data.data.jwt_token);
          setConnectionError(null);
          localStorage.setItem("jwt_token", res.data.data.jwt_token);
          connected = true;
          axios.defaults.headers.common["x-access-token"] =
            res.data.data.jwt_token; /* Necéssaire pour pouvoir faire la requete de join */
        } else {
          setConnectionError(true);
          connected = false;
        }
      });
    if (connected) {
      return connected;
    } else {
      return false;
    }
  };

  const logout = () => {
    setJwtToken(null);
    setConnectionError(null);
    localStorage.removeItem("jwt_token");
    window.location.href = "/login";
  };

  const contextValue = useMemo(() => {
    return { jwtToken, connectionError, login, logout };
  }, [jwtToken, connectionError]);

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
