import SERVER_ADDRESS from "../../aaHoc/global";
import React, { PureComponent, useEffect, useState } from "react";
import axios from "axios";
import {
  PieChart,
  Pie,
  Legend,
  Tooltip,
  ResponsiveContainer,
  Label,
} from "recharts";

import exportedStyles from "../../styles/_settings.scss";
import FormatThousands from "../Format/FormatThousands";

const Chart_HoldingsByLedger = () => {
  const [ledgerData, setLedgerData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(
          SERVER_ADDRESS + "/api/holdings/get-current",
          {
            groupBy: "ledger",
          }
        );
        const formattedData = response.data.map((item) => ({
          name: item.ledger_name,
          pctHolding: item.pct_total_holding * 100,
          value: item.holding_value,
        }));

        setLedgerData(formattedData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="recharts-custom-tooltip">
          {console.log(payload[0])}
          <p className="label">{`${
            payload[0].name
          } : ${payload[0].value.toFixed(2)} €`}</p>
        </div>
      );
    }

    return null;
  };
  return (
    <div className="chart-container holdingsbyledger-chart">
      <PieChart width={400} height={300}>
        <Pie
          data={ledgerData}
          cx="50%"
          cy="50%"
          outerRadius={100}
          fill={exportedStyles.color2}
          dataKey="value"
          label={({
            cx,
            cy,
            midAngle,
            innerRadius,
            outerRadius,
            value,
            pctHolding,
            index,
          }) => {
            const RADIAN = Math.PI / 180;
            // eslint-disable-next-line
            const radius = 25 + innerRadius + (outerRadius - innerRadius);
            // eslint-disable-next-line
            const x = cx + radius * Math.cos(-midAngle * RADIAN);
            // eslint-disable-next-line
            const y = cy + radius * Math.sin(-midAngle * RADIAN);
            return (
              <text
                x={x}
                y={y}
                fill={exportedStyles.color1}
                textAnchor={x > cx ? "start" : "end"}
                dominantBaseline="central"
              >
                {ledgerData[index].name} | {pctHolding.toFixed(2)}%
              </text>
            );
          }}
        />
        <Tooltip content={<CustomTooltip />} />
      </PieChart>
    </div>
  );
};

export default Chart_HoldingsByLedger;
