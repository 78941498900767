import React from "react";
import Navigation from "../components/Navigation";

const UserProfile = () => {
  return (
    <div className="page-component">
      <Navigation />
      <h1>CLIENT PROFILE PAGE</h1>
    </div>
  );
};

export default UserProfile;
