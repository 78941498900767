import React from "react";
import Navigation from "../components/Navigation";
import FundTransactions_Table from "../components/FundTransactions/FundTransactions_Table";
import FundTransaction_Form from "../components/FundTransactions/FundTransaction_Form";
import ActionModal from "../components/ActionModal";
import Accounts_Table from "../components/Accounts/Accounts_Table";

const FundAdministration = () => {
  return (
    <div className="page-component">
      <Navigation />
      <section>
        <h2>Accounts Summary</h2>
        <div className="divider"></div>
        <Accounts_Table />
        <h2>Fund history</h2>
        <div className="divider"></div>
        <div className="table-buttons-container">
          <ActionModal
            modalHeader="FUND TRANSACTION"
            modalBody={<FundTransaction_Form />}
          />
        </div>

        <FundTransactions_Table />
      </section>
    </div>
  );
};

export default FundAdministration;
