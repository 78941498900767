import React from "react";

const FormatThousands = ({ num }) => {
  let formattedNum = "";
  if (Math.round(num).toString().length < 2) {
    formattedNum = new Intl.NumberFormat("fr-FR", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 5,
    }).format(num);
  } else if (Math.round(num).toString().length >= 4) {
    formattedNum = num?.toLocaleString("fr-FR", {
      useGrouping: true,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  } else {
    formattedNum = num?.toLocaleString("fr-FR", {
      useGrouping: true,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }

  return <span>{formattedNum}</span>;
};

export default FormatThousands;
