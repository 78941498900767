import React from "react";
import Holdings_Table from "../components/Holdings/Holdings_Table";
import Navigation from "../components/Navigation";

import Chart_HoldingsByLedger from "../components/Charts/Chart_HoldingsByLedger";
import Chart_Nav from "../components/Charts/Chart_Nav";
import FundQuickInfo from "../components/FundQuickInfo";
import HoldingsByLedger_Table from "../components/Holdings/HoldingsByLedger_Table";

const FundOverview = () => {
  return (
    <div className="page-component">
      <Navigation />
      <FundQuickInfo publicDataOnly={false} />
      <section>
        <div>
          <h2>Fund Historical NAV Chart</h2>
          <div className="divider"></div>
          <Chart_Nav showToggleAum={true} />
        </div>
      </section>
      <section>
        <h2>Allocation by Ledger</h2>
        <div className="divider"></div>
        <Chart_HoldingsByLedger />
      </section>
      <section>
        <div>
          <h2>Fund Holdings by ledger</h2>
          <div className="divider"></div>
          <HoldingsByLedger_Table />
        </div>
      </section>
      <section>
        <div>
          <h2>Fund Holdings</h2>
          <div className="divider"></div>
          <Holdings_Table />
        </div>
      </section>
    </div>
  );
};

export default FundOverview;
