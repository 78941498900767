import React from "react";
import Navigation from "../components/Navigation";
import Test from "../components/Test";
import FundQuickInfo from "../components/FundQuickInfo";
import Holdings_SummaryTable from "../components/Holdings/Holdings_SummaryTable";

import Chart_Nav from "../components/Charts/Chart_Nav";
import Chart_FearAndGreedIndex from "../components/Charts/Chart_FearAndGreedIndex";

const Home = () => {
  return (
    <div className="page-component">
      <Navigation />
      <section>
        <FundQuickInfo publicDataOnly={true} />
      </section>
      <section>
        <div>
          <h2>Fund Historical NAV Chart</h2>
          <div className="divider"></div>
          <Chart_Nav showToggleAum={false} />
        </div>
      </section>
      <section>
        <div>
          <h2>Fear And Greed Index</h2>
          <div className="divider"></div>
          <Chart_FearAndGreedIndex />
        </div>
      </section>
      <section>
        <h2>Fund Holdings</h2>
        <div className="divider"></div>
        <Holdings_SummaryTable />
      </section>
    </div>
  );
};

export default Home;
