import SERVER_ADDRESS from "../../aaHoc/global";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { Modal } from "react-bootstrap";
import FormatThousands from "../Format/FormatThousands";

const PortfolioTransactions_Success = ({ transactionData, mappingsData }) => {
  const transaction = transactionData?.transactionData;
  const holdings = transactionData?.holdingsNewData;

  const [transactionName, setTransactionName] = useState();
  const [coinName, setCoinName] = useState();
  const [ledgerName, setLedgerName] = useState();
  useEffect(() => {
    console.log(transactionData);
    setTransactionName(
      mappingsData?.transactions.find(
        (item) => item.id === parseInt(transaction?.type)
      )
    );
    setCoinName(
      mappingsData?.coins.find(
        (item) => item.id === parseInt(transaction?.coinId)
      )
    );
    setLedgerName(
      mappingsData?.ledgers.find(
        (item) => item.id === parseInt(transaction?.ledgerId)
      )
    );
  }, [transactionData, mappingsData]);
  return (
    <div>
      <Modal
        centered
        className="dark-modal fullscreen-modal"
        show={true}
        scrollable={true}
        onHide={() => {
          window.location.reload();
        }}
      >
        <Modal.Header closeButton>
          <h3>Transaction added successfully</h3>
        </Modal.Header>
        <Modal.Body>
          <div className="table-container two-column-table">
            <h3>Transaction Details </h3>
            <div className="divider"></div>
            <div className="table-line">
              <div>Date</div>
              <div>{transaction?.reportDate}</div>
            </div>
            <div className="table-line">
              <div>Transaction Type</div>
              <div>{transactionName?.name}</div>
            </div>
            <div className="table-line">
              <div>Coin name</div>
              <div>{coinName?.coin_name}</div>
            </div>
            <div className="table-line">
              <div>Ledger Name</div>
              <div>{ledgerName?.ledger_name}</div>
            </div>
            <div className="table-line">
              <div>Quantity</div>
              <div>{transaction?.quantity}</div>
            </div>
            <div className="table-line">
              <div>Value</div>
              <div>{transaction?.value} €</div>
            </div>
          </div>

          <h3>New account balance </h3>
          <div className="divider"></div>
          <div className="table-container two-column-table">
            <div className="table-line">
              <div>Amount</div>
              <div>{<FormatThousands num={holdings?.holding_amount} />}</div>
            </div>
            <div className="table-line">
              <div>Value</div>
              <div>{<FormatThousands num={holdings?.holding_value} />}€</div>
            </div>
            <div className="table-line">
              <div>% Total Holding</div>
              <div>{holdings?.pct_total_holding * 100}%</div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default PortfolioTransactions_Success;
