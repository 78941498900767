import React, { useState } from "react";
import FormatThousands from "../Format/FormatThousands";

const HoldingsByLedger_Tableline = ({ ledger }) => {
  const percentFormater = (num) => {
    let newNum = (num * 100).toFixed(2);
    return newNum + "%";
  };

  return (
    <div className="table-line">
      <div>{ledger.coin_name}</div>
      <div>{<FormatThousands num={ledger.holding_amount} />}</div>
      <div>{<FormatThousands num={ledger.current_price} />} €</div>
      <div>{<FormatThousands num={ledger.holding_value} />} €</div>
    </div>
  );
};

export default HoldingsByLedger_Tableline;
