import SERVER_ADDRESS from "../../aaHoc/global";
import React, { useEffect, useState } from "react";
import axios from "axios";
import Accounts_TableLine from "./Accounts_TableLine";

const Accounts_Table = () => {
  const [accountsData, setAccountsData] = useState([]);

  useEffect(() => {
    axios.get(SERVER_ADDRESS + "/api/accounts/get").then((res) => {
      setAccountsData(res.data);
    });
  }, []);

  const tableHeader = [
    "Account",
    "Nosh",
    "Holding Value",
    "Avg NAV (Sub)",
    "% Profit",
  ];
  return (
    <div className="accounts-table">
      <div className="table-container">
        <ul className="table-header">
          {tableHeader.map((el) => (
            <li key={el}>
              <label htmlFor={el}>{el}</label>
            </li>
          ))}
        </ul>
        {console.log(accountsData)}
        {accountsData?.map((account, index) => (
          <Accounts_TableLine
            account={account}
            key={account.id}
            index={index}
          />
        ))}
      </div>
    </div>
  );
};

export default Accounts_Table;
