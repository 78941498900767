import React from "react";
import Navigation from "../components/Navigation";
import PortfolioTransaction_Form from "../components/PortfolioTransactions/PortfolioTransaction_Form";
import PortflioTransactions_Table from "../components/PortfolioTransactions/PortfolioTransactions_Table";
import ActionModal from "../components/ActionModal";
import FundQuickInfo from "../components/FundQuickInfo";

const PortfolioManagement = () => {
  return (
    <div className="page-component">
      <Navigation />
      <section>
        <h2>Portfolio History</h2>
        <div className="divider"></div>
        <div className="table-buttons-container">
          <ActionModal
            modalHeader="PORTFOLIO TRANSACTION"
            modalBody={<PortfolioTransaction_Form />}
          />
        </div>
        <PortflioTransactions_Table />
      </section>
    </div>
  );
};

export default PortfolioManagement;
