import React, { useState } from "react";
import { Modal } from "react-bootstrap";

const ActionModal = ({ modalHeader, modalBody }) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <div>
      <div
        className="button-action-modal squared-flex-button"
        onClick={() => {
          setShowModal(true);
        }}
      >
        Create New Transaction
      </div>

      <Modal
        centered
        className="dark-modal"
        show={showModal}
        scrollable={true}
        onHide={() => {
          setShowModal(false);
        }}
      >
        <Modal.Header closeButton>
          <h3>{modalHeader}</h3>
        </Modal.Header>
        <Modal.Body>{modalBody}</Modal.Body>
      </Modal>
    </div>
  );
};

export default ActionModal;
