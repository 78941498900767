import SERVER_ADDRESS from "../../aaHoc/global";
import React, { useEffect, useState } from "react";
import axios from "axios";
import Holdings_TableLine from "./Holdings_TableLine";

const Holdings_Table = () => {
  const [holdingsData, setHoldingsData] = useState([]);

  useEffect(() => {
    axios
      .post(SERVER_ADDRESS + "/api/holdings/get-current", {
        groupBy: "coin",
      })
      .then((res) => {
        setHoldingsData(res.data);
      });
  }, []);

  const tableHeader = ["Name", "Amount", "Price", "Value", "% Holding"];
  return (
    <div className="holdings-table">
      <div className="table-container">
        <ul className="table-header">
          {tableHeader.map((el) => (
            <li key={el}>
              <label htmlFor={el}>{el}</label>
            </li>
          ))}
        </ul>
        {holdingsData &&
          holdingsData.map((coin, index) => (
            <Holdings_TableLine
              coin={coin}
              key={coin.coin_name}
              index={index}
            />
          ))}
      </div>
    </div>
  );
};

export default Holdings_Table;
