import 'bootstrap/dist/css/bootstrap.min.css';

import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import FundOverview from './pages/FundOverview';
import FundAdministration from './pages/FundAdministration';
import PortfolioManagement from './pages/PortfolioManagement';
import UserProfile from './pages/UserProfile';
import { Container } from 'react-bootstrap';
import Login from './pages/Login';

import { AuthProvider } from './utils/auth';
import RequireAuth from './utils/RequireAuth';

function App() {
  return (
    <div className="App">
      <Container>
        <AuthProvider>
          <BrowserRouter>
            <Routes>
              <Route path="/home" element={<Home />} />
              <Route path="/fund-overview" element={<RequireAuth><FundOverview /></RequireAuth>} />
              <Route path="/fund-admin" element={<RequireAuth><FundAdministration /></RequireAuth>} />
              <Route path="/portfolio-management" element={<RequireAuth><PortfolioManagement /></RequireAuth>} />
              <Route path="/user-profile" element={<RequireAuth><UserProfile /></RequireAuth>} />
              <Route path="/login" element={<Login />} />
              <Route path="*" element={<Home />} />
            </Routes>
          </BrowserRouter>
        </AuthProvider>
      </Container>
    </div>
  );
}

export default App;
