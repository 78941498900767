import SERVER_ADDRESS from "../../aaHoc/global";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { Modal } from "react-bootstrap";
import FormatThousands from "../Format/FormatThousands";

const FundTransaction_Form = () => {
  const [reportDate, setReportDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [transactionType, setTransactionType] = useState("");
  const [transactionValue, setTransactionValue] = useState("");
  const [transactionAverageNav, setTransactionAverageNav] = useState("");
  const [accountId, setAccountId] = useState("");
  const [nosh, setNosh] = useState("");
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const [accountNewBalance, setAccountNewBalance] = useState();

  const [errorMessage, setErrorMessage] = useState();
  const [listTransactionType, setListTransactionType] = useState([]);
  useEffect(() => {
    axios
      .post(SERVER_ADDRESS + "/api/mapping/transactions-type/get", {
        perimeter: "Fund",
      })
      .then((res) => {
        if (res.status === 200) {
          setListTransactionType(res.data);
          setTransactionType(res.data[0].id);
        } else {
          alert("Une erreur s'est produite. Veuillez réessayer");
        }
      });
  }, []);

  const [listAccounts, setListAccounts] = useState([]);
  useEffect(() => {
    axios.get(SERVER_ADDRESS + "/api/mapping/accounts/get").then((res) => {
      if (res.status === 200) {
        setListAccounts(res.data);
        setAccountId(res.data[0].account_id);
      } else {
        alert("Une erreur s'est produite. Veuillez réessayer");
      }
    });
  }, []);

  const accountData = listAccounts.find(
    (account) => account.account_id === parseInt(accountId)
  );
  const transactionData = listTransactionType.find(
    (transaction) => transaction.id === parseInt(transactionType)
  );

  const handleValueUpdate = (tValue) => {
    setTransactionValue(tValue);
    setNosh((tValue / transactionAverageNav).toFixed(3));
  };
  const handleNavUpdate = (NavValue) => {
    setTransactionAverageNav(NavValue);
    setNosh((transactionValue / NavValue).toFixed(3));
  };
  const handleSubmit = () => {
    if (transactionValue !== "" && transactionAverageNav !== "") {
      axios
        .post(SERVER_ADDRESS + "/api/fund-transactions/create", {
          reportDate,
          transactionType,
          transactionValue,
          transactionAverageNav,
          accountId,
          nosh,
        })
        .then((res) => {
          setAccountNewBalance(res);
          setShowSuccessModal(true);
          setErrorMessage("");
        });
    } else {
      setErrorMessage("NAV and Transaction value fields are mandatory.");
    }
  };

  return (
    <div>
      <div className="form-transaction">
        <div className="input-area">
          <div className="input-label">Report Date (Only today atm)</div>
          <input
            type="date"
            className="input-value"
            placeholder="Nom"
            onChange={(e) => setReportDate(e.target.value.replace(/"/g, "'"))}
            value={reportDate}
          />
        </div>
        <div className="input-area">
          <div className="input-label">Type</div>
          <select
            className="input-value"
            onChange={(e) => {
              setTransactionType(e.target.value);
            }}
            value={transactionType}
          >
            {listTransactionType?.map((item) => (
              <option key={item.id} value={parseInt(item.id)}>
                {item.name}
              </option>
            ))}
          </select>
        </div>
        <div className="input-area">
          <div className="input-label">Value (€)</div>
          <input
            type="number"
            className="input-value"
            placeholder="Transaction Value"
            onChange={(e) => {
              handleValueUpdate(e.target.value.replace(/"/g, "'"));
            }}
            value={transactionValue}
          />
        </div>
        <div className="input-area">
          <div className="input-label">Average NAV (€)</div>
          <input
            type="number"
            className="input-value"
            placeholder="Average NAV"
            onChange={(e) => handleNavUpdate(e.target.value.replace(/"/g, "'"))}
            value={transactionAverageNav}
          />
        </div>
        <div className="input-area">
          <div className="input-label">Account</div>
          <select
            className="input-value"
            onChange={(e) => {
              setAccountId(parseInt(e.target.value));
            }}
            value={accountId}
          >
            {listAccounts?.map((item) => (
              <option key={item.account_id} value={item.account_id}>
                {item.firstname + " " + item.lastname + " - " + item.name}
              </option>
            ))}
          </select>
        </div>
        <div className="input-area">
          <div className="input-label">Number of Shares</div>
          <input
            type="number"
            className="input-value font-color1"
            placeholder="Number of Shares"
            onChange={(e) => setNosh(e.target.value.replace(/"/g, "'"))}
            value={nosh}
            disabled
          />
        </div>
      </div>
      {errorMessage ? <div className="error-message">{errorMessage}</div> : ""}
      <div
        className="squared-fullwidth-button"
        onClick={() => {
          handleSubmit();
        }}
      >
        Create Transaction
      </div>
      {showSuccessModal ? (
        <Modal
          centered
          className="dark-modal fullscreen-modal"
          show={showSuccessModal}
          scrollable={true}
          onHide={() => {
            setShowSuccessModal(false);
            window.location.reload();
          }}
        >
          <Modal.Header closeButton>
            <h3>Transaction added successfully</h3>
          </Modal.Header>
          <Modal.Body>
            <div className="table-container two-column-table">
              <h3>Transaction Details </h3>
              <div className="divider"></div>
              <div className="table-line">
                <div>Transaction Type</div>
                <div>{transactionData?.name}</div>
              </div>
              <div className="table-line">
                <div>Account</div>
                <div>
                  {accountData.firstname +
                    " " +
                    accountData.lastname +
                    " | " +
                    accountData?.name}
                </div>
              </div>
              <div className="table-line">
                <div>Date</div>
                <div>{reportDate}</div>
              </div>
              <div className="table-line">
                <div>Value</div>
                <div>{transactionValue}€</div>
              </div>
              <div className="table-line">
                <div>Average NAV</div>
                <div>{transactionAverageNav}€</div>
              </div>
            </div>
            <h3>New account balance </h3>
            <div className="divider"></div>
            <div className="table-container two-column-table">
              <div className="table-line">
                <div>Number of shares</div>
                <div>
                  {<FormatThousands num={accountNewBalance?.data.nosh} />}
                </div>
              </div>
              <div className="table-line">
                <div>New Account Balance</div>
                <div>
                  {
                    <FormatThousands
                      num={accountNewBalance?.data.holding_value}
                    />
                  }
                  €
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      ) : (
        ""
      )}
    </div>
  );
};

export default FundTransaction_Form;
