import React from "react";
import FormatPerformances from "../Format/FormatPerformances";
import FormatThousands from "../Format/FormatThousands";

const Accounts_TableLine = ({ account }) => {
  return (
    <div className="table-line">
      <div>
        <div>{account.firstname + " " + account.lastname}</div>
        <div className="tableline-second-row">{account.name}</div>
      </div>
      <div>
        <div>{account.nosh}</div>
        <div className="tableline-second-row">
          <br></br>
        </div>
      </div>
      <div>
        {<FormatThousands num={account.holding_value} />}€
        <div className="tableline-second-row">
          <br></br>
        </div>
      </div>
      <div>
        {account.avg_sub_nav}
        <div className="tableline-second-row">
          <br></br>
        </div>
      </div>
      <div>
        {<FormatPerformances num={account.profit_pct} />}
        <div className="tableline-second-row">
          <br></br>
        </div>
      </div>
    </div>
  );
};

export default Accounts_TableLine;
