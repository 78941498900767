import React, { useState } from "react";
import FormatThousands from "../Format/FormatThousands";

const Holdings_TableLine = ({ coin }) => {
  const percentFormater = (num) => {
    let newNum = (num * 100).toFixed(2);
    return newNum + "%";
  };

  return (
    <div className="table-line">
      <a
        className="link-style"
        target="_blank"
        href={"https://www.coingecko.com/fr/pi%C3%A8ces/" + coin.coingecko_id}
      >
        <div className="tablecol-coinname">
          <div>
            <img
              className="coin-img-round small-icon"
              src={coin.image}
              alt="logo"
            />
          </div>
          <div>{coin.coin_name}</div>
        </div>
      </a>
      <div>{<FormatThousands num={coin.holding_amount} />}</div>
      <div>{<FormatThousands num={coin.current_price} />} €</div>
      <div>{<FormatThousands num={coin.holding_value} />} €</div>
      <div>{percentFormater(coin.pct_total_holding)}</div>
    </div>
  );
};

export default Holdings_TableLine;
