import SERVER_ADDRESS from "../../aaHoc/global";
import React, { useEffect, useState } from "react";
import axios from "axios";
import HoldingsByLedger_Tableline from "./HoldingsByLedger_Tableline";
import FormatThousands from "../Format/FormatThousands";

import exportedStyles from "../../styles/_settings.scss";

import Select from "react-select";

const HoldingsByLedger_Table = () => {
  const [holdingsData, setHoldingsData] = useState([]);
  const [listOfLedgers, setListOfLedgers] = useState([]);
  const [currentLedger, setCurrentLedger] = useState("Select a ledger");

  const [filteredHoldingsData, setFilteredHoldingsData] = useState([{}]);
  const [totalHoldingValue, setTotalHoldingValue] = useState(0);

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "white" : "black",
      backgroundColor: state.isSelected ? exportedStyles.color2 : "white",
      "&:hover": {
        backgroundColor: exportedStyles.color2Lighter,
      },
    }),
  };

  const options = [
    ...listOfLedgers.map((ledger) => ({ value: ledger, label: ledger })),
  ];

  useEffect(() => {
    axios
      .post(SERVER_ADDRESS + "/api/holdings/get-current", {
        groupBy: "all",
      })
      .then((res) => {
        setHoldingsData(res.data);
        setListOfLedgers([
          ...new Set(res.data.map((item) => item.ledger_name)),
        ]);
      });
  }, []);

  useEffect(() => {
    setFilteredHoldingsData(
      holdingsData?.filter((item) => item.ledger_name === currentLedger)
    );
  }, [currentLedger]);
  useEffect(() => {
    setTotalHoldingValue(
      filteredHoldingsData
        ?.map((item) => item.holding_value)
        .reduce((a, b) => a + b, 0)
    );
  }, [filteredHoldingsData]);

  const handleSelectChange = (selectedItem) => {
    setCurrentLedger(selectedItem.value);
  };

  const tableHeader = [
    "Coin Name",
    "Holdings Amount",
    "Current Price",
    "Value",
  ];
  return (
    <div className="holdingsbyledger-table">
      <div className="table-container">
        <div className="table-line-above-table">
          <Select
            className="dropdown-menu-container"
            value={{ value: currentLedger, label: currentLedger }}
            onChange={handleSelectChange}
            options={options}
            placeholder="Select a Ledger"
            styles={customStyles}
          />
          <div>Total Value</div>
          <div>{<FormatThousands num={totalHoldingValue} />} €</div>
        </div>
        <ul className="table-header">
          {tableHeader.map((el) => (
            <li>
              <label htmlFor={el}>{el}</label>
            </li>
          ))}
        </ul>
        {filteredHoldingsData?.map((ledger, index) => (
          <HoldingsByLedger_Tableline
            key={ledger.id}
            ledger={ledger}
            index={index}
          />
        ))}
      </div>
    </div>
  );
};

export default HoldingsByLedger_Table;
