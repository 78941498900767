import React from "react";
import FormatThousands from "../Format/FormatThousands";

const FundTransactions_TableLine = ({ transaction }) => {
  const formattedReportDate = new Date(
    transaction.report_date
  ).toLocaleDateString("fr-FR");

  return (
    <div className="table-line">
      <div>
        <div>{formattedReportDate}</div>
        <div className="tableline-second-row">{transaction.tra_type_name}</div>
      </div>
      <div>{transaction.firstname + " " + transaction.lastname}</div>
      <div>{transaction.tra_nosh}</div>
      <div>{<FormatThousands num={transaction.tra_value} />} €</div>
      <div>{<FormatThousands num={transaction.tra_avg_nav} />} €</div>
    </div>
  );
};

export default FundTransactions_TableLine;
