import SERVER_ADDRESS from "../../aaHoc/global";
import React, { useEffect, useState } from "react";
import axios from "axios";
import ActionModal from "../ActionModal";
import PortfolioTransactions_Success from "./PortfolioTransactions_Success";

const PortfolioTransaction_Form = () => {
  const [reportDate, setReportDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [ledgerId, setLedgerId] = useState("");
  const [coinId, setCoinId] = useState("");
  const [type, setType] = useState("");
  const [value, setValue] = useState("");
  const [quantity, setQuantity] = useState("");

  const [direction, setDirection] = useState();

  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const handleCloseSuccess = () => {
    setShowSuccessModal(!showSuccessModal);
  };
  const [mappingsData, setMappingsData] = useState();
  const [listTransactionType, setListTransactionType] = useState([]);

  const [errorMessage, setErrorMessage] = useState();
  useEffect(() => {
    axios
      .post(SERVER_ADDRESS + "/api/mapping/transactions-type/get", {
        perimeter: "Portfolio",
      })
      .then((res) => {
        if (res.status === 200) {
          setListTransactionType(res.data);
          setType(res.data[0].id);
          setDirection(res.data[0].direction);
          setMappingsData((prevMappingsData) => ({
            ...prevMappingsData,
            transactions: res.data,
          }));
        } else {
          alert("Une erreur s'est produite. Veuillez réessayer");
        }
      });
  }, []);

  const [listLedger, setListLedger] = useState([]);
  useEffect(() => {
    axios.get(SERVER_ADDRESS + "/api/mapping/ledgers/get").then((res) => {
      if (res.status === 200) {
        setListLedger(res.data);
        setLedgerId(res.data[0].id);
        setMappingsData((prevMappingsData) => ({
          ...prevMappingsData,
          ledgers: res.data,
        }));
      } else {
        alert("Une erreur s'est produite. Veuillez réessayer");
      }
    });
  }, []);

  const [listCoin, setListCoin] = useState([]);
  useEffect(() => {
    axios.get(SERVER_ADDRESS + "/api/mapping/coins/get").then((res) => {
      if (res.status === 200) {
        setListCoin(res.data);
        setCoinId(res.data[0].id);
        setMappingsData((prevMappingsData) => ({
          ...prevMappingsData,
          coins: res.data,
        }));
      } else {
        alert("Une erreur s'est produite. Veuillez réessayer");
      }
    });
  }, []);
  const [responseData, setResponseData] = useState();
  const handleSubmit = async () => {
    if (value !== "" && quantity !== "") {
      axios
        .post(SERVER_ADDRESS + "/api/portfolio-transactions/create", {
          reportDate,
          ledgerId,
          coinId,
          type,
          direction,
          value,
          quantity,
        })
        .then((res) => {
          setResponseData(res.data);
          handleCloseSuccess();
          setErrorMessage("");
        });
    } else {
      setErrorMessage("Value and Quantity fields are mandatory.");
    }
  };
  return (
    <div>
      <div className="form-transaction">
        <div className="input-area">
          <div className="input-label">Report Date (Only today atm)</div>
          <input
            type="date"
            className="input-value"
            placeholder="Report Date"
            onChange={(e) => setReportDate(e.target.value.replace(/"/g, "'"))}
            value={reportDate}
          />
        </div>
        <div className="input-area">
          <div className="input-label">Ledger Name</div>
          <select
            className="input-value"
            placeholder="Ledger Name"
            onChange={(e) => {
              setLedgerId(e.target.value);
            }}
            value={ledgerId}
          >
            {listLedger?.map((item) => (
              <option key={item.id} value={item.id}>
                {item.ledger_name}
              </option>
            ))}
          </select>
        </div>
        <div className="input-area">
          <div className="input-label">Coin Name</div>
          <select
            type="text"
            className="input-value"
            placeholder="Coin Name"
            onChange={(e) => {
              setCoinId(e.target.value.replace(/"/g, "'"));
            }}
            value={coinId}
          >
            {listCoin?.map((item) => (
              <option key={item.id} value={item.id}>
                {item.coin_name}
              </option>
            ))}
          </select>
        </div>
        <div className="input-area">
          <div className="input-label">Type</div>
          <select
            type="text"
            className="input-value"
            placeholder="Transaction Type"
            onChange={(e) => {
              setType(e.target.value.replace(/"/g, "'"));
              const transactionTypeDirection = listTransactionType.find(
                (traType) =>
                  traType.id === parseInt(e.target.value.replace(/"/g, "'"))
              );
              setDirection(transactionTypeDirection.direction);
            }}
            value={type}
          >
            {listTransactionType?.map((item) => (
              <option key={item.id} value={item.id}>
                {item.name}
              </option>
            ))}
          </select>
        </div>
        <div className="input-area">
          <div className="input-label">Value (€)</div>
          <input
            type="number"
            className="input-value"
            placeholder="Value"
            onChange={(e) => {
              setValue(e.target.value);
            }}
            value={value}
          />
        </div>
        <div className="input-area">
          <div className="input-label">Quantity</div>
          <input
            type="number"
            className="input-value"
            placeholder="Quantity"
            onChange={(e) => setQuantity(e.target.value.replace(/"/g, "'"))}
            value={quantity}
          />
        </div>
      </div>
      {errorMessage ? <div className="error-message">{errorMessage}</div> : ""}
      <div
        className="squared-fullwidth-button"
        onClick={() => {
          handleSubmit();
        }}
      >
        Create Transaction
      </div>
      {showSuccessModal ? (
        <PortfolioTransactions_Success
          transactionData={responseData}
          mappingsData={mappingsData}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default PortfolioTransaction_Form;
