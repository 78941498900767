import React from "react";
import FormatThousands from "../Format/FormatThousands";
import FormatPerformances from "../Format/FormatPerformances";

const PortfolioTransactions_TableLine = ({ transaction }) => {
  const formattedReportDate = new Date(
    transaction.report_date
  ).toLocaleDateString("en-GB");
  return (
    <div className="table-line">
      <div>
        <a
          className="link-style"
          target="_blank"
          href={
            "https://www.coingecko.com/fr/pi%C3%A8ces/" +
            transaction.coingecko_id
          }
        >
          <div className="tablecol-coinname">
            <div>
              <img
                className="coin-img-round small-icon"
                src={transaction.image}
                alt="logo"
              />
            </div>
            <div>{transaction.coin_name}</div>
          </div>
          <div className="tableline-second-row">{transaction.ledger_name}</div>
        </a>
      </div>
      <div>{formattedReportDate}</div>
      <div>
        {transaction.tra_type_name}
        <div className="tableline-second-row">
          <FormatPerformances num={transaction.trade_perf} />
        </div>
      </div>
      <div>
        {<FormatThousands num={transaction.tra_coin_quantity} />}{" "}
        <div className="tableline-second-row">
          {(
            transaction.tra_total_value / transaction.tra_coin_quantity
          ).toFixed(2)}
          {" €"}
        </div>
      </div>
      <div>{<FormatThousands num={transaction.tra_total_value} />} €</div>
    </div>
  );
};

export default PortfolioTransactions_TableLine;
