import SERVER_ADDRESS from "../../aaHoc/global";
import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Area,
  AreaChart,
  CartesianGrid,
  Tooltip,
  XAxis,
  YAxis,
  linearGradient,
  ResponsiveContainer,
  ReferenceLine,
} from "recharts";

import exportedStyles from "../../styles/_settings.scss";

const Chart_FearAndGreedIndex = () => {
  const [duration, setDuration] = useState(365);
  const [navData, setNavData] = useState();
  const headerData = [
    [30, "1 mois"],
    [91, "3 mois"],
    [181, "6 mois"],
    [365, "1 an"],
    [730, "2 ans"],
    [1800, "5 ans"],
    [3000, "Max"],
  ];
  useEffect(() => {
    const fetchData = async () => {
      try {
        const now = new Date();
        const thresholdDate = new Date(now - duration * 24 * 60 * 60 * 1000);

        const response = await axios.get(
          `${SERVER_ADDRESS}/api/fearandgreed/get`
        );
        const filteredData = response.data.filter((item) =>
          duration <= 365
            ? new Date(item.report_date) > thresholdDate
            : new Date(item.report_date) > thresholdDate &&
              new Date(item.report_date).getDay() === now.getDay()
        );

        const formattedData = filteredData.map((item) => ({
          date: new Date(item.report_date).toLocaleDateString(),
          indexLevel: item.fg_index_value,
        }));

        setNavData(formattedData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [duration]);

  const horizontalBars = [
    { level: 25, label: "Fear" },
    { level: 75, label: "Greed" },
  ];
  return (
    <div className="chart-container">
      <div className="nav-chart">
        <div className="btn-container">
          {headerData.map((el) => {
            return (
              <div
                key={el[0]}
                htmlFor={"btn" + el[0]}
                onClick={() => setDuration(el[0])}
                className={el[0] === duration ? "active-btn" : ""}
              >
                {el[1]}
              </div>
            );
          })}
        </div>
        <ResponsiveContainer width="100%">
          <AreaChart
            data={navData}
            margin={{ top: 10, right: 10, left: 10, bottom: 0 }}
          >
            <defs>
              <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                <stop
                  offset="7%"
                  stopColor={exportedStyles.chartColor1}
                  stopOpacity={0.8}
                />
                <stop
                  offset="93%"
                  stopColor={exportedStyles.chartColor2}
                  stopOpacity={0}
                />
              </linearGradient>
            </defs>
            <XAxis dataKey="date" />
            <YAxis domain={[0, 100]} />
            <Tooltip />
            <Area
              type="monotone"
              dataKey="indexLevel"
              stroke={exportedStyles.chartColor1}
              fillOpacity={1}
              fill="url(#colorUv)"
            />
            {horizontalBars.map((bar) => (
              <ReferenceLine
                y={bar.level}
                key={bar.level}
                stroke={exportedStyles.highlightColor}
                label={bar.label}
              />
            ))}
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default Chart_FearAndGreedIndex;
