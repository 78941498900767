import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Navigation from "../components/Navigation";
import { useAuth } from "../utils/auth";

const Login = () => {
  const auth = useAuth();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [showPassword, setShowPassword] = useState(false);

  const handleSubmit = async () => {
    const connected = await auth.login({ email, password });
    if (connected) {
      window.location.href = "/home";
    }
  };
  if (auth.userData?.idUser) {
    window.location.href = "/home";
  }

  return (
    <div className="page-component login-page">
      <Navigation />
      <div className="login-container">
        <h1 className="centered">Se connecter</h1>
        <div className="divider"></div>
        <div className="login-input-container">
          <div className="login-input">
            <input
              type="text"
              className="login-input-text"
              placeholder="Email"
              onChange={(e) => setEmail(e.target.value.replace(/"/g, "'"))}
              value={email}
            />
          </div>
          <div className="login-input">
            <input
              type={showPassword ? "text" : "password"}
              placeholder="Password"
              className="login-input-text"
              onChange={(e) => setPassword(e.target.value.replace(/"/g, "'"))}
              value={password}
            />
            <i
              className={
                showPassword
                  ? "input-password-toggle bi bi-eye-slash-fill"
                  : "input-password-toggle bi bi-eye-fill"
              }
              onClick={() => {
                setShowPassword(!showPassword);
              }}
            ></i>
          </div>
          {auth?.connectionError && (
            <div>
              <p className="error-message">
                Un problème est survenu. Vérifiez votre email et/ou votre mot de
                passe
              </p>
            </div>
          )}
        </div>
        <div className="login-button-area">
          <div
            className="squared-shorttext-button"
            onClick={() => {
              handleSubmit();
            }}
          >
            Se connecter
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
