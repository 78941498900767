import SERVER_ADDRESS from "../aaHoc/global";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { Col, Row } from "react-bootstrap";
import FormatThousands from "./Format/FormatThousands";
import FormatPerformances from "./Format/FormatPerformances";

const FundQuickInfo = ({ publicDataOnly }) => {
  const [fundData, setFundData] = useState([]);
  const [hideAmount, setHideAmount] = useState(false);
  const formattedDate = new Date(fundData.report_date).toLocaleString("fr-FR", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });

  useEffect(() => {
    axios.get(SERVER_ADDRESS + "/api/aum/get-current").then((res) => {
      setFundData(res.data[0]);
    });
  }, []);

  const handleRefreshData = () => {
    axios.post(SERVER_ADDRESS + "/api/prices/update-latest").then((res) => {
      if (res) {
        window.location.reload();
      } else {
        console.log("Page not refreshed");
      }
    });
  };
  return (
    <div className="highlight-box">
      {publicDataOnly ? (
        ""
      ) : (
        <div className="hide-toggle">
          <span
            className={
              hideAmount
                ? "bi bi-eye-slash hide-toggle"
                : "bi bi-eye hide-toggle"
            }
            onClick={() => {
              setHideAmount(!hideAmount);
            }}
          ></span>
        </div>
      )}
      <div className="flex-container">
        {publicDataOnly ? (
          <Row>
            <Col sm={6}>
              <div className="centered-text">
                <div className="fundquickinfo-value">{fundData.nav} €</div>
                <h3>Net Asset value</h3>
              </div>
            </Col>
            <Col sm={6}>
              <div className="centered-text">
                <div className="fundquickinfo-value">
                  {<FormatPerformances num={fundData.pf_1w} />}
                </div>
                <h3>1-Week Performance</h3>
              </div>
            </Col>
          </Row>
        ) : (
          <Row>
            <Col sm={4}>
              <div className="centered-text">
                <div className="fundquickinfo-value">{fundData.nav} €</div>
                <h3>Net Asset value</h3>
              </div>
            </Col>
            <Col sm={4}>
              <div className="centered-text">
                {/* <div className="font-color4">{fundData.aum}</div> */}
                <div className="fundquickinfo-value">
                  <span className={hideAmount ? "" : "blurred"}>
                    <FormatThousands num={fundData.aum} /> €
                  </span>
                </div>
                <h3>Assets Under Management</h3>
              </div>
            </Col>
            <Col sm={4}>
              <div className="centered-text">
                <div className="fundquickinfo-value">
                  <span className={hideAmount ? "" : "blurred"}>
                    {fundData.nosh ? fundData.nosh : "-"}
                  </span>
                </div>
                <h3>Number of Shares</h3>
              </div>
            </Col>
          </Row>
        )}
      </div>
      <div className="centered-text comment">Last update : {formattedDate}</div>
      {publicDataOnly ? (
        ""
      ) : (
        <div
          className="squared-fullwidth-button centered-div"
          onClick={() => {
            handleRefreshData();
          }}
        >
          Refresh data
        </div>
      )}
    </div>
  );
};

export default FundQuickInfo;
