import SERVER_ADDRESS from "../aaHoc/global";
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import axios from "axios";
import LogoutButton from "./LogoutButton";
import { useAuth } from "../utils/auth";

const Navigation = () => {
  const auth = useAuth();

  const [fundData, setFundData] = useState([]);
  useEffect(() => {
    axios.get(SERVER_ADDRESS + "/api/aum/get-current").then((res) => {
      setFundData(res.data[0]);
    });
  }, []);
  return (
    <div className="header-bar">
      <div className="fixed-header-bar">
        <a href="/fund-overview">
          <div className="header-title">CryptoDynamic</div>
        </a>
        <div className="header-fundnav-container">
          <div className="fundnav-value">{fundData.nav} €</div>
        </div>
      </div>
      {auth.jwtToken ? (
        <div className="navigation">
          <div className="nav-link-container">
            <NavLink
              to="/home"
              className={(nav) =>
                nav.isActive ? "navlink nav-active" : "navlink"
              }
            >
              <div className="navbar-smalltext">HOME</div>
            </NavLink>
          </div>
          <div className="nav-link-container">
            <NavLink
              to="/fund-overview"
              className={(nav) =>
                nav.isActive ? "navlink nav-active" : "navlink"
              }
            >
              <div className="navbar-smalltext">
                FUND<br></br>OVERVIEW
              </div>
            </NavLink>
          </div>
          <div className="nav-link-container">
            <NavLink
              to="/fund-admin"
              className={(nav) =>
                nav.isActive ? "navlink nav-active" : "navlink"
              }
            >
              <div className="navbar-smalltext">
                FUND<br></br>ADMIN
              </div>
            </NavLink>
          </div>
          <div className="nav-link-container">
            <NavLink
              to="/portfolio-management"
              className={(nav) =>
                nav.isActive ? "navlink nav-active" : "navlink"
              }
            >
              <div className="navbar-smalltext">
                PORTFOLIO<br></br>MANAGEMENT
              </div>
            </NavLink>
          </div>
          {/* <div className="nav-link-container">
          <NavLink
            to="/user-profile"
            className={(nav) =>
              nav.isActive ? "navlink nav-active" : "navlink"
            }
          >
            <div className="navbar-smalltext">
              USER<br></br>PROFILE
            </div>
          </NavLink>
        </div> */}

          <LogoutButton />
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default Navigation;
