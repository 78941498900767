import React from "react";

const FormatPerformances = ({ num }) => {
  let formattedNum = "";
  if (num < 0) {
    return <span className="font-minusColor">{(num * 100).toFixed(2)}%</span>;
  } else {
    return <span className="font-plusColor">{(num * 100).toFixed(2)}%</span>;
  }
};

export default FormatPerformances;
